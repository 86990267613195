/**
 * @method GET
 * @description получаем header bar
 * */

export interface IApiContentHeaderBarItem {
  shownValuePopUps: Array<any>
  active: boolean
  groupId: number
  showToMembers: boolean
  showToGuest: boolean
  showToExpired: boolean
  openLinkInNewTab: boolean
  createAt: string
  startDate: string
  endDate: string
  showTrigger: number
  timeForTrigger: string
  name: string
  affiliateId?: string
  template: string
  showCancelButton: boolean
  mainImage?: string
  mainImageMobile?: string
  smallImage?: string
  title: string
  titleMobile: string
  subTitle: string
  subTitleMobile: string
  mainText?: string
  bgColor: string
  textColor: string
  gradient1: string
  gradient2: string
  buttonText: string
  buttonUrl: string
  buttonSubText?: string
  urls: Array<any>
  uuid: string
  showAllPages: boolean
  alwaysShow: boolean
}
export interface IApiValuePopUpProps {
  active?: boolean,
  showToGuest: boolean
  showToMembers: boolean
  showToInactive?: boolean
  showToExpired?: boolean,
  showAllPages?: boolean,
  affiliateId: string
  shownValuePopUps: Array<any>
  url: string,
  saleEvent?: string
}

export default async function apiContentHeaderBar (payload: IApiValuePopUpProps) {
  const endpoint = `/front/show-header`

  return await useAsyncQuery(async ({ axios, pathPopup }) => {
    return await axios.post(pathPopup + endpoint, payload)
  })
}
